import React, { Fragment } from 'react'

export default class InstagramFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return null;
        }
        return (
            <Fragment>
                <hr style={{ marginBottom: 30 }} />
                <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
                <iframe
                    title="Instagram widget"
                    src="https://cdn.lightwidget.com/widgets/8b0da3482a185802af820fac7704ef0a.html"
                    scrolling="no"
                    allowtransparency="true"
                    className="lightwidget-widget"
                    style={{ width: "100%", border: "0", overflow: "hidden", height: "30vw", maxHeight: "360px" }}>
                </iframe>
            </Fragment>
        );
    }
}
