import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ReactPlayer from 'react-player/youtube'

import Layout from '../components/Layout'
import Features from '../components/Features'
import InstagramFeed from '../components/InstagramFeed'

import useDeviceDetect from '../helpers/useDeviceDetect'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const IndexPageTemplate = ({
  heading,
  mainpitch,
  description,
  intro,
  handleChange,
  handleSubmit,
  showThanks
}) => {
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <ReactPlayer className="react-player" url="https://youtu.be/q2wRVHDbW-E" loop={true} playing={true} muted={true} width="100%" height={isMobile ? 360 : 650} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  {(mainpitch.title || mainpitch.description) &&
                    <div className="content">
                      {mainpitch.title &&
                        <div className="tile">
                          <h1 className="title">{mainpitch.title}</h1>
                        </div>}
                      {mainpitch.description &&
                        <div className="tile">
                          <h3 className="subtitle">{mainpitch.description}</h3>
                        </div>}
                    </div>}
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                        {heading}
                      </h3>
                      {description && <p>{description}</p>}
                    </div>
                  </div>
                  <Features gridItems={intro.blurbs} />
                  <hr style={{ marginBottom: 30 }} />
                  <div className="columns">
                    <div className="column is-6 has-text-centered">
                      <h2>Sumate a nuestra comunidad</h2>
                      <Link className="button" to="/sumate">
                        Ver información de planes
                      </Link>
                    </div>
                    <div className="column is-6 has-text-centered">
                      <h2>Visitanos cuando quieras</h2>
                      <a className="button" title="calendly" href="https://calendly.com/colaboroar" target="_blank" rel="noopener noreferrer">Reservar turno</a>
                    </div>
                  </div>
                  <InstagramFeed />
                  <hr style={{ marginBottom: 30 }} />
                  <div className="columns">
                    <div className="column is-12 has-text-centered suscripcion-novedades">
                      <h2>Suscripción a novedades</h2>
                      {showThanks && 'Gracias!'}
                      {!showThanks &&
                        <form
                          name="novedades"
                          method="post"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          onSubmit={handleSubmit}
                        >
                          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                          <input type="hidden" name="form-name" value="novedades" />
                          <div hidden>
                            <label>Don’t fill this out:{' '} <input name="bot-field" onChange={handleChange} /></label>
                          </div>
                          <input
                            className="input"
                            placeholder="Nombre"
                            type={'text'}
                            name={'name'}
                            onChange={handleChange}
                            id={'name'}
                          />
                          <input
                            className="input"
                            placeholder="Email"
                            type={'email'}
                            name={'email'}
                            onChange={handleChange}
                            id={'email'}
                            required={true}
                          />
                          <button className="button" type="submit">
                            Enviar
                          </button>
                        </form>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.setState({ showThanks: true }))
      .catch(error => alert(error))
  }
  render() {
    const { frontmatter } = this.props.data.markdownRemark
    return (
      <Layout>
        <IndexPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}
          heading={frontmatter.heading}
          mainpitch={frontmatter.mainpitch}
          description={frontmatter.description}
          intro={frontmatter.intro}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          showThanks={this.state.showThanks}
        />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
        }
      }
    }
  }
`
